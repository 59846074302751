.header {
  width: 100%;
  background-color: transparent;
  z-index: 999;
  position: fixed;
  top: 0;
  transition: all var(--transition),
    background-color 400ms cubic-bezier(0.4, 0, 0.2, 1);
}

.header-scroll {
  background: linear-gradient(
    90deg,
    rgba(21, 23, 20, 0.75) 0%,
    rgba(21, 24, 18, 0.75) 50%,
    rgba(28, 29, 24, 0.75) 100%
  );
  box-shadow: 0 0 15px 0px rgba(86, 86, 86, 0.6);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}

.header.header-hidden {
  transform: translateY(-100%);
  opacity: 0;
}

.header .container {
  padding: 10px 15px;
  position: relative;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-svg-logo {
  width: 112px;
  height: 38px;
  transition: all var(--transition);
  z-index: 10;
  fill: var(--main-white);
}

.header-svg-logo.is-open {
  fill: var(--main-white);
  z-index: 200;
}

.header-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.header-address-content {
  display: none;
}

.burger-icon {
  position: absolute;
  z-index: 200;
  width: 20px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
}

.burger-close {
  right: 0;
}

.burger-icon svg {
  width: 20px;
  height: 20px;
}

.burger-icon.burger-open svg {
  stroke: var(--main-white);
}

.burger-icon.burger-close svg {
  stroke: var(--main-white);
}

.lang-container {
  display: flex;
  gap: 15px;
  justify-content: space-around;
  align-items: center;
  width: 50px;
}

.imitation {
  width: 50px;
}

@media screen and (min-width: 768px) {
  .burger-icon {
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  .header {
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    padding: 0 20px;
  }

  .header-scroll {
    background: rgba(255, 255, 255, 0.85);
    box-shadow: 0 0 15px 0px rgba(86, 86, 86, 0.6);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
  }

  .header-nav {
    justify-content: space-around;
  }

  .header .container {
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    max-width: 1280px;
  }

  .imitation {
    display: none;
  }

  .header-address-content {
    display: block;
    font-style: normal;
  }

  .header-address-text {
    color: #21251d;
    opacity: 0.8;
    text-align: right;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  .header-address-number {
    color: #21251d;

    text-align: right;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
  }

  .header-svg-logo {
    width: 130px;
    height: 43px;
    fill: var(--main-black);
  }

  .header-svg-logo {
    transition: all var(--transition);
  }

  .header-svg-logo:is(:hover, :focus) {
    fill: var(--accent);
  }

  .lang-container {
    width: 200px;
  }
}

@media screen and (min-width: 1920px) {
}
