.burger-menu-item {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.burger-menu-link {
  font-size: 16px;
  font-weight: 500;
}
