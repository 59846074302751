.contacts {
  padding: 25px 10px 10px;
  background-image: linear-gradient(rgb(0, 0, 0, 0.98), rgb(0, 0, 0, 0.98)),
    url(../../img/bcg.webp);
}

.contacts .container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 31px;
  padding: 35px 15px;
  max-width: 400px;
  margin: 0 auto;
}

.contacts-data {
  max-width: 200px;
}

.title-container {
  display: flex;
  gap: 10px;
}

.svg {
  fill: var(--main-white);
  margin-top: 5px;
}

.contact-label {
  color: var(--accent);
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.contact-info {
  display: block;
  color: var(--main-white);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 30px;
}

.footer-link-logo-desktop {
  display: none;
}

.footer-svg-logo {
  fill: var(--main-white);
}

.footer-link-logo {
  align-self: center;
}

@media screen and (min-width: 1280px) {
  .contacts {
    padding: 0;
  }

  .contacts .container {
    padding: 80px 30px;
    flex-direction: row;
    max-width: 1280px;
  }

  .contacts-data {
    max-width: 400px;
  }

  .contact-label {
    font-size: 20px;
  }

  .contact-info {
    font-size: 16px;
  }

  .footer-link-logo-desktop {
    margin-top: 50px;
    display: block;
    width: 130px;
    height: 43px;
  }

  .footer-link-logo {
    display: none;
  }

  .svg {
    margin-top: 0;
  }
}
