.container {
  position: relative;
}

.select-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  overflow: hidden;
  width: 30px;
  height: 30px;
  position: relative;
}

.cur-lang-icon {
  width: 100%;
  height: 100%;
  transform: scale(1);
  position: absolute;
}

.is-hidden {
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
}

.lang-list-container {
  position: absolute;
  height: auto;
  width: 180px;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  padding: 20px;
  left: 0;
  bottom: -420%;
  transform: translateX(-75%);
  background-color: rgba(0, 0, 0, 0.95);
  box-shadow: 0 0 15px 0px rgba(86, 86, 86, 0.6);
  transition: all var(--transition);
}

.lang-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.lang-item-btn {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  color: var(--main-white);
}

.lang-item-icon {
  width: 25px;
  pointer-events: none;
}

.lang-item-icon-use {
  pointer-events: none;
}

@media screen and (min-width: 1280px) {
  .lang-list-container {
    width: 200px;
    border-radius: 14px;
    padding: 20px;
    left: 50%;
    bottom: -310%;
    transform: translateX(-71%);
    background: rgba(255, 255, 255, 0.95);
  }

  .select-btn {
    width: 40px;
    height: 40px;
  }

  .lang-item-btn {
    color: var(--main-black);
  }
}
