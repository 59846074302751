.burger-block {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-image: url(../../img/drone.webp),
    linear-gradient(rgb(0, 0, 0, 0.95), rgb(0, 0, 0, 0.95)),
    url(../../img/bcg.webp),
    linear-gradient(rgb(255, 255, 255, 1), rgb(255, 255, 255, 1));
  padding: 0px 0px 40px;
  color: var(--main-white);
  transform: translateX(-100%);
  opacity: 0;
  transition: all var(--transition);
  z-index: 9999;
  background-position: 75% 100%, 0, 0, 0;
  background-repeat: no-repeat, repeat, repeat;
}

.burger-block.is-open {
  transform: translateX(0);
  opacity: 1;
}

.burger-block .container {
  display: grid;
  grid-template-rows: 60px 1fr 80px;
  height: 100%;
  padding: 5px 15px;
}

.burger-header {
  display: flex;
  justify-content: center;
  align-self: center;
  position: relative;
  width: 100%;
  padding: 0 15px;
}

.burger-svg-logo {
  width: 112px;
  height: 38px;
  fill: var(--main-white);
}

.burger-title {
  text-transform: uppercase;
  color: var(--accent);
  font-size: 36px;
  font-weight: 900;
  align-self: center;
  justify-self: center;
}

.burger-img {
  display: none;
  bottom: 100px;
  transform: translateX(-15%);
  position: absolute;
}

.burger-block.is-open .burger-img {
  display: block;
}

.burger-icon {
  position: absolute;
  z-index: 200;
  display: flex;
  flex-direction: column;
  width: 20px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
}

.burger-close {
  right: 15px;
}

.burger-icon svg {
  width: 20px;
  height: 20px;
}

.burger-icon.burger-open svg {
  stroke: var(--main-white);
}

.burger-icon.burger-close svg {
  stroke: var(--main-white);
}

@media screen and (min-width: 768px) {
  .burger-icon {
    display: none;
  }
}
