.header-slogan-list {
  display: none;
  width: 200px;
}

@media screen and (min-width: 1280px) {
  .header-slogan-list {
    display: block;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 125%;
    text-transform: uppercase;
  }
}
