.contacts-form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 60px;
}

.contacts-form-block {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
  padding: 0;
}

.contacts-form-label {
  color: var(--accent);
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 20px;
}

.input-container {
  position: relative;
  margin-bottom: 20px;
}

.input-container input,
.input-container textarea {
  border: 0;
  border: none;
  border-bottom: 3px solid #555;
  background: transparent;
  width: 100%;
  padding: 5px 0;
  font-size: 12px;
  color: var(--main-white);
  border-radius: 0;
  outline: none;
  resize: vertical;
}

.input-container label {
  position: absolute;
  top: 5px;
  left: 0px;
  font-size: 14px;
  font-weight: 500;
  color: var(--main-white);
  pointer-events: none;
  transition: all 0.5s ease-in-out;
  text-transform: uppercase;
  display: block;
  opacity: 0.8;
}

.input-container input:focus,
.input-container textarea:focus {
  border: none;
  border-bottom: 3px solid var(--accent);
  outline: none;
}

.input-container input:focus ~ label,
.input-container input:is(:valid) ~ label,
.input-container textarea:focus ~ label,
.input-container textarea:is(:valid) ~ label {
  top: -12px;
  font-size: 12px;
  opacity: 1;
}

.form-btn {
  padding: 7px 20px;
  border: none;
  color: var(--main-white);
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 2px;
  border: 1px solid var(--accent);
  background-color: transparent;
  transition: all var(--transition);
}

.form-btn:is(:hover, :focus, :active) {
  background-color: var(--accent);
  color: var(--main-black);
  box-shadow: 0 0 10px 1px rgba(255, 255, 255, 0.5);
}

@media screen and (min-width: 1280px) {
  .contacts-form {
    width: 400px;
  }

  .contacts-form-label {
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  .input-container {
    margin-bottom: 80px;
  }

  .input-container label {
    font-size: 14px;
    letter-spacing: 0.06em;
    top: 12px;
  }

  .input-container input,
  .input-container textarea {
    padding: 10px 0;
    font-size: 14px;
  }

  .form-btn {
    font-size: 16px;
    padding: 10px 30px;
    border-radius: 5px;
  }
}
