/* Reset styles */
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

p:last-child {
  margin-bottom: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: currentColor;
  text-decoration: none;
}

button {
  cursor: pointer;
  padding: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--color) !important;
  caret-color: var(--color);
}

@font-face {
  font-family: "Fixel";
  src: url(./fonts/FixelText-Regular.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Fixel";
  src: url(./fonts/FixelText-Medium.woff2) format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: 'Fixel';
  src: url() format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
} */

@font-face {
  font-family: "Fixel";
  src: url(./fonts/FixelText-SemiBold.woff2) format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Fixel";
  src: url(./fonts/FixelText-ExtraBold.woff2) format("woff2");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/* ---------------- Rubik ------------------- */

@font-face {
  font-family: "Rubik";
  src: url(./fonts/Rubik-Regular.ttf);
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Rubik";
  src: url(./fonts/Rubik-Medium.ttf);
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Rubik";
  src: url(./fonts/Rubik-MediumItalic.ttf);
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Rubik";
  src: url(./fonts/Rubik-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Rubik";
  src: url(./fonts/Rubik-Bold.ttf);
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Rubik";
  src: url(./fonts/Rubik-ExtraBold.ttf);
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Rubik";
  src: url(./fonts/Rubik-ExtraBold.ttf);
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/* ---------------------------------------------------------- */

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

/* .container {
  margin: 0 auto;
  max-width: 400px;
}

section {
  margin: 0 auto;
  padding: 15px 10px 10px;
} */

@media screen and (min-width: 375px) {
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 1280px) {
  /* .container {
    padding: 10px 0px;
    max-width: 1280px;
  }

  section {
    padding: 20px;
  } */
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: rgb(0, 0, 0, 0.8);
}

/*-----------------Notif------------------- */

.notyf {
  padding: 20px !important;
}

.notyf .clipboard-notif-success {
  border-radius: 2px;
  max-width: 100%;
  background-color: var(--main-black);
  box-shadow: 0 0px 7px 0 rgba(255, 184, 3, 0.9);
  font-size: 14px;
}

@media screen and (min-width: 1280px) {
  .notyf .clipboard-notif-success {
    font-size: 16px;
    border-radius: 5px;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms var(--transition);
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
